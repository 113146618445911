import { LinksFunction, LoaderFunctionArgs, json } from "@remix-run/node";
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useRouteError,
} from "@remix-run/react";
import { captureRemixErrorBoundaryError } from "@sentry/remix";
import React, { useEffect } from "react";
import { ToastContainer, toast as notify } from "react-toastify";
import toastCssUrl from "react-toastify/dist/ReactToastify.css?url";
import { getToast } from "remix-toast";
import { Navbar } from "./components/navbar";
import globalCssUrl from "./styles/global.css?url";
import navbarCssUrl from "./styles/navbar.css?url";
import tailwindCssUrl from "./tailwind.css?url";
import { getSite } from "./utils/auth.util";
import { getErrorMessage } from "./utils/error.util";

export const links: LinksFunction = () => [
  { rel: "stylesheet", href: tailwindCssUrl },
  {
    rel: "stylesheet",
    href: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css",
  },
  { rel: "stylesheet", href: toastCssUrl },
  { rel: "stylesheet", href: globalCssUrl },
  { rel: "stylesheet", href: navbarCssUrl },
];

type LayoutLoader = typeof loader;
export const loader = async ({ request }: LoaderFunctionArgs) => {
  const site = await getSite(request);
  const { toast, headers } = await getToast(request);
  return json({ site, toast }, { headers });
};

const Layout: React.FC<React.PropsWithChildren> = ({ children }) => (
  <html lang="en">
    <head>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Meta />
      <Links />
    </head>
    <body>{children}</body>
  </html>
);

export default ({ children }: React.PropsWithChildren) => {
  const { site, toast } = useLoaderData<LayoutLoader>();

  useEffect(() => {
    if (toast) {
      notify(toast.message, {
        type: toast.type,
        autoClose: toast.type === "error" ? 5000 : 2000,
      });
    }
  }, [toast]);

  return (
    <Layout>
      <Navbar
        title={site.name}
        links={{
          Home: "/",
          About: "/about",
          "Music Schedule": "/schedule",
          Events: "/events",
          Media: "/media",
          Contact: "/contact",
        }}
      />
      {children ?? <Outlet />}
      <ToastContainer />
      <ScrollRestoration />
      <Scripts />
    </Layout>
  );
};

export const ErrorBoundary: React.FC = () => {
  const error = useRouteError();
  console.error(error);
  captureRemixErrorBoundaryError(error);

  const details = getErrorMessage(error);

  return (
    <Layout>
      <h4>Something went wrong:</h4>
      <div className="text-red-600 whitespace-pre-wrap">{details}</div>
      <Scripts />
    </Layout>
  );
};
